<template>
  <a-modal  v-model:visible="modalVisible" :footer="null" width="800px" style="top: 10px" >
    <template #title>
      <div class="modalTitle" :style="{borderColor: store.state.themeColor}">
        选择教材章节
      </div>
    </template>
    <div class="content">
      <a-form ref="formRef" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" class="formClass">
        <a-form-item name="name" label="名称">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item name="gradePeriod" label="学段">
          <common-radio v-model:value="formState.gradePeriod" :options="formOpts.gradePeriod" />
        </a-form-item>
        <a-form-item name="subject" label="学科">
          <CommonRadio v-model:value="formState.subject" :options="formOpts.subject" />
        </a-form-item>
        <a-form-item name="pressVersion" label="版本">
          <CommonRadio v-model:value="formState.pressVersion" :options="formOpts.pressVersion" />
        </a-form-item>
        <a-form-item label="标签">
          <a-select
            v-model:value="formState.tag"
            mode="multiple"
            placeholder="可选择标签"
            :options="formOpts.tags"
            @search="handleTagsChange"
          />
        </a-form-item>
        <select-book-block
          ref="sonRef"
          :type="bookType"
          :form="formState"
          v-model:value="bookSectionValue"
          :queryParams="extraQueryParams"
        />
      </a-form>
    </div>
    <div class="footer">
      <a-button type="primary" size="large" class="submitBtn" @click="handleSubmit">
        确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { reactive, ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import CommonRadio from '@/components/common/CommonRadio'
import {
  getGradePeriodEnum,
  getPressVersionEnum,
  getSubjectEnum
} from '@/services/teaching-material'
import { getTags, getTeacherTags } from '@/services/common'
import SelectBookBlock from './SelectBookBlock'
import { debounce } from '@/utils/common'

export default {
  name: 'SelectBookModal',
  components: {
    SelectBookBlock,
    CommonRadio
  },
  props: {
    visible: Boolean,
    type: {
      type: Number,
      default: 1
    },
    queryParams: Object
  },
  emits: ['ok', 'update:visible'],
  setup (props, { emit }) {
    const extraQueryParams = computed(() => props.queryParams)
    const bookType = computed(() => props.type)
    const sonRef = ref()
    const store = useStore()
    const modalVisible = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val)
      }
    })
    const formRef = ref()
    const fileList = ref([])
    const loading = ref(false)

    const formState = ref({
      name: '',
      subject: 0,
      gradePeriod: 0,
      pressVersion: 0,
      tags: []
    })
    const bookSectionValue = ref({
      bookSectionId: 0,
      bookSections: 0
    })

    const formOpts = reactive({
      subject: [],
      gradePeriod: [],
      pressVersion: [],
      bookOptions: [],
      tagParams: {
        pageSize: 50,
        page: 1,
        keyword: ''
      }
    })

    const getTagsOptions = debounce(async () => {
      const params = {
        per_page: formOpts.tagParams.pageSize,
        current: formOpts.tagParams.page,
        keyword: formOpts.tagParams.keyword
      }
      const getTagsService = bookType.value === 1 ? getTags : getTeacherTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })

    const getOptions = () => {
      getSubjectEnum().then((data) => {
        formOpts.subject = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getPressVersionEnum().then((data) => {
        formOpts.pressVersion = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getGradePeriodEnum().then((data) => {
        formOpts.gradePeriod = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getTagsOptions()
    }

    const formatCascaderChildrenOpts = (children) => {
      return children.map(i => {
        const item = {
          label: i.name,
          value: i.id
        }
        if (i.children) {
          item.children = formatCascaderChildrenOpts(i.children)
        }
        return item
      })
    }

    const handleTagsChange = () => {
      console.log(1)
    }

    const handleSubmit = () => {
      emit('ok', {
        values: bookSectionValue.value.bookSectionId,
        labels: bookSectionValue.value.bookSections
      })
    }

    const handleReset = () => {
      formState.value = {
        name: '',
        subject: 0,
        gradePeriod: 0,
        pressVersion: 0,
        tags: []
      }
      if (sonRef.value) {
        sonRef.value.handleReset()
      }
    }
    // 重置tag
    // watch([modalVisible], () => {
    //   if (modalVisible.value) {
    //     // handleReset()
    //     getTagsOptions()
    //   }
    // }, { deep: true, immediate: true })

    onBeforeMount(() => {
      getOptions()
    })

    return {
      sonRef,
      labelCol: {
        span: 2
      },
      wrapperCol: {
        span: 22
      },
      bookType,
      formRef,
      bookSectionValue,
      formOpts,
      formState,
      store,
      modalVisible,
      fileList,
      loading,
      extraQueryParams,
      handleTagsChange,
      handleSubmit,
      handleReset
    }
  }
}
</script>

<style lang="less" scoped>
.modalTitle{
  margin-top: 10px;
  border-left: 3px solid;
  padding-left: 20px;
}
.content{
  padding: 0 18px
}
.footer{
  text-align: center;
  margin-top: 12px;
  .submitBtn{
    width: 118px;
    height: 40px;
    border-radius: 4px;
  }
}
.coverImg{
  max-height: 200px;
  width: 200px;
}

.formClass{
  .ant-row,.ant-form-item{
    margin-bottom: 5px;
  }
}
</style>
