<template>
  <a-row :gutter="[16, 16]">
    <a-col :span="10" style="background-color: #f9fafc; margin-top: 24px; height: 35vh ;">
      <a-list size="small" :bordered="false" :data-source="tableState.list" >
        <template #renderItem="{ item }">
          <a-list-item
            @click="handleSelectBook(item)"
            :class="item.active && 'active-book'"
            >{{ item.name }}</a-list-item
          >
        </template>
      </a-list>
      <a-pagination
        style="margin-top: 10px"
        v-model:current="tableState.pagination.current"
        v-model:pageSize="tableState.pagination.pageSize"
        :total="tableState.pagination.total"
        simple
        @change="handleTableChange"
        show-quick-jumper
      />
    </a-col>
    <a-col :span="14">
      <a-card :bordered="false">
        <div style="height: 35vh ;overflow-y: auto; background-color: #f9fafc">
          <a-empty v-if="!treeState.list.length">
            <template #description> 暂无数据 </template>
          </a-empty>
          <a-tree
            show-line
            selectable
            :tree-data="treeState.list"
            v-model:selectedKeys="treeState.selectedKeys"
            @select="handleSelectNode"
          >
            <template #icon>1</template>
          </a-tree>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, ref, computed, watch } from 'vue'
import { getOfficialBookSections, getOfficialList, getPersonalList, getPersonalBookSections } from '@/services/teaching-material'
import { debounce } from '@/utils/common'
import { useStore } from 'vuex'

export default {
  name: 'SelectBookBlock',
  props: {
    form: Object,
    type: {
      type: Number,
      default: 1
    },
    queryParams: Object
  },
  setup (props, { emit }) {
    const store = useStore()
    const themeColor = ref(store.state.themeColor)
    const extraQueryParams = computed(() => props.queryParams || {})
    const bookType = computed(() => props.type)
    const formState = ref({})
    const tableState = reactive({
      list: [],
      loading: true,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 8,
        showTotal: total => `共${total}条`,
        showQuickJumper: true,
        simple: true
      }
    })
    const value = reactive({})
    const treeState = reactive({
      list: [],
      selectedKeys: []
    })

    const getBooks = debounce(async (extraParams) => {
      console.log(121, extraQueryParams.value)
      const params = {
        ...formState.value,
        per_page: tableState.pagination.pageSize,
        page: 1,
        ...extraQueryParams.value
      }
      if (formState.value.subject) {
        params.subject_cat_id = formState.value.subject
      }
      if (formState.value.gradePeriod) {
        params.grade_period_cat_id = formState.value.gradePeriod
      }
      if (formState.value.pressVersion) {
        params.press_version_cat_id = formState.value.pressVersion
      }
      if (formState.value.tags.length > 0) {
        params.tag = formState.value.tags
      }
      const getBookService = bookType.value === 1 ? getOfficialList : getPersonalList
      const { items, pagination } = await getBookService({ ...params, ...extraParams })
      tableState.list = items
      tableState.pagination.pageSize = pagination.pageSize
      tableState.pagination.current = pagination.current
      tableState.pagination.total = pagination.total
      treeState.list = []
      treeState.selectedKeys = []
    })

    const formatTreeData = (arr) => {
      return arr.map(i => {
        const item = {
          title: i.name,
          key: i.id
        }
        if (i.children) {
          item.children = formatTreeData(i.children)
        }
        return item
      })
    }

    const handleTableChange = (pag) => {
      console.log(pag)
      tableState.pagination.current = pag
      getBooks({ page: pag })
    }

    const handleSelectBook = async item => {
      console.log(item, 'sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss')
      tableState.list.map(i => {
        i.active = i.id === item.id
        return i
      })
      item.active = true
      value.bookId = item.id
      value.bookName = item.name
      value.bookSectionId = [item.id]
      value.bookSections = [item.name]
      emit('update:value', {
        bookSectionId: value.bookSectionId,
        bookSections: value.bookSections
      })
      const getBookSections = bookType.value === 1 ? getOfficialBookSections : getPersonalBookSections
      treeState.list = formatTreeData(await getBookSections(item.id))
    }

    const handleSelectNode = (selectedKeys, e) => {
      if (e.selectedNodes.length !== 0) {
        const item = e.selectedNodes[0].props
        value.bookSectionId = [value.bookId, item.key]
        value.bookSections = [value.bookName, item.title]
      } else {
        value.bookSectionId = [value.bookId]
        value.bookSections = [value.bookName]
      }
    }

    const handleReset = () => {
      treeState.list = []
      treeState.selectedKeys = []
      getBooks()
    }
    watch(() => bookType, () => {
      getBooks()
    }, { deep: true })

    watch(() => props.form, (v) => {
      formState.value = v
      console.log(value.bookSectionId, value.bookSections, 'ssssssssw1w1w1w1')
      value.bookSectionId = undefined
      value.bookSections = undefined
      console.log(value.bookSectionId, value.bookSections, 'aaaaaaaaaaaaaaaaaaaaaa')
      getBooks()
    }, { deep: true, immediate: true })

    watch(() => treeState.selectedKeys, () => {
      // console.log(value.bookSectionId, value.bookSections, 'ssssssssw1w1w1w1')
      // value.bookSectionId = undefined
      // value.bookSections = undefined
      // console.log(value.bookSectionId, value.bookSections, 'aaaaaaaaaaaaaaaaaaaaaa')
      emit('update:value', {
        bookSectionId: value.bookSectionId,
        bookSections: value.bookSections
      })
    }, { deep: true })

    return {
      formState,
      tableState,
      treeState,
      themeColor,
      handleSelectBook,
      handleTableChange,
      handleSelectNode,
      handleReset
    }
  }
}
</script>

<style lang="less"  scoped>
.active-book{
  background-color: v-bind('themeColor');
}
</style>
